import React, { useEffect, useState, useMemo } from 'react';
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import { UseAPI } from '../../../services/UseAPI';
import { COLUMNS } from './Columns';
import PageTitle from '../../layouts/PageTitle';
import { GlobalFilter } from './GlobalFilter';
import './filtering.css';
import { Editor } from '@tinymce/tinymce-react';
import uuid from 'node-uuid'
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import swal from 'sweetalert';
import _, { map } from 'underscore'

function TryoutParticipantsTambah() {
	const [data, setData] = useState([]);
	const columns = useMemo(() => COLUMNS,[]);
    const [participant,setParticipant] = useState([])
    const [questionSoal,setQuestionSoal] = useState([])
    const [questionSoalYangAda,setQuestionSoalYangAda] = useState([])
    const { tryout_id } = useParams();
	useEffect(() => {
        let tryout_soal = [];
		let participants = [];
		UseAPI('participant_tryouts/peserta/'+tryout_id,{},'GET').then((tryout_resp)=>{
			console.log("tryout_resp",tryout_resp);
            tryout_resp.map((item)=> {
                participants.push(item.user_id);
            })
			console.log("tryout_resp",tryout_resp);
			UseAPI('users/tryout',{},'GET').then((resp_user)=>{
				console.log("resp_user",resp_user);
				participants.map((item)=>{
					console.log(item);
					resp_user.map((subitem,subkey)=>{
						if (subitem.id == item) {
							console.log(subkey);
							resp_user.splice(subkey,1)
						}
					})
				})
				setData(resp_user); 
				console.log("data",data);
			})
	
		})


		
        // console.log("tryout_soal",tryoutSoal);
	}, []);

	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	}, useFilters, useGlobalFilter, usePagination)

	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance


	const {globalFilter, pageIndex} = state  
    const daftar = (user_id) => {
        swal({
            title: "Anda yakin ?",
            html:"true",
            text:'',
            icon: "warning",
            buttons: true,
          }).then((saved) => {
            if (saved) {
                UseAPI('participant_tryouts',{user_id:user_id,tryout_id:tryout_id},'POST').then((tryout_resp_awal)=>{

					let participants = [];
					UseAPI('participant_tryouts/peserta/'+tryout_id,{},'GET').then((tryout_resp)=>{
						console.log("tryout_resp",tryout_resp);
						tryout_resp.map((item)=> {
							participants.push(item.user_id);
						})
						console.log("tryout_resp",tryout_resp);
						UseAPI('users/tryout',{},'GET').then((resp_user)=>{
							console.log("resp_user",resp_user);
							participants.map((item)=>{
								console.log(item);
								resp_user.map((subitem,subkey)=>{
									if (subitem.id == item) {
										console.log(subkey);
										resp_user.splice(subkey,1)
									}
								})
							})
							setData(resp_user); 
							console.log("data",data);
						})
				
					})
										   
                    
                })
                  
              swal("Peserta berhasil di daftar", {
                icon: "success",
              });
            }
        })        
    }

	return (
		<>
		<PageTitle activeMenu="Daftar" motherMenu="Peserta" />
		<div className="card">
			<div className="card-header">
				<h4 className="card-title">Daftarkan Peserta</h4>
			</div>
			<div className="card-body">
				<div className="table-responsive">
					{/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
					<table {...getTableProps()} className="table display">
						<thead>
						{headerGroups.map(headerGroup => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									<th style={{width:'15%'}}>										
										Aksi
									</th>

									{headerGroup.headers.map(column => (
										<th {...column.getHeaderProps()} style={{width:column.width}}>
											{column.render('Header')}
											{console.log(column)}
											{column.canFilter ? column.render('Filter') : null}
										</th>
									))}
								</tr>
						))}
						</thead> 
						<tbody {...getTableBodyProps()} className="" >
						
							{page.map((row) => {
								console.log(row);
								prepareRow(row)
								return(
									<tr {...row.getRowProps()}>
										<td>
                                            <Button className="me-2" variant="primary btn-rounded" onClick={()=>daftar(row.original.id)}>
                                                <span className="btn-icon-start text-primary">
                                                    <i className="fa fa-download color-primary" />
                                                </span>
                                                Daftar
                                            </Button>                                            
										</td>
										{row.cells.map((cell) => {
											return <td key={uuid()} {...cell.getCellProps()}> {cell.render('Cell')} </td>

										})}
									</tr>
								)
							})}
						</tbody>
					</table>
					<div className="d-flex justify-content-between">
						<span>
							Page{' '}
							<strong>
								{pageIndex + 1} of {pageOptions.length}
							</strong>{''}
						</span>
						<span className="table-index">
							Go to page : {' '}
							<input type="number" 
								className="ml-2"
								defaultValue={pageIndex + 1} 
								onChange = {e => { 
									const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
									gotoPage(pageNumber)
								} } 
							/>
						</span>
					</div>
					<div className="text-center mb-3">	
						<div className="filter-pagination  mt-3">
							<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
							
							<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
								Previous
							</button>
							<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
								Next
							</button>
							<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>

	);
}

export default TryoutParticipantsTambah;
