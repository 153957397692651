import React, { useEffect, useState, useMemo } from 'react';
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import { UseAPI } from '../../../services/UseAPI';
import { COLUMNS } from './Columns';
import PageTitle from '../../layouts/PageTitle';
import { GlobalFilter } from './GlobalFilter';
import './filtering.css';
import { Editor } from '@tinymce/tinymce-react';
import uuid from 'node-uuid'
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import swal from 'sweetalert';
import _, { map } from 'underscore'

function TryoutSoalTambah() {
	const [data, setData] = useState([]);
	const columns = useMemo(() => COLUMNS,[]);
    const [tryoutSoal,setTryoutSoal] = useState([])
    const [questionSoal,setQuestionSoal] = useState([])
    const [questionSoalYangAda,setQuestionSoalYangAda] = useState([])
    const { id } = useParams();
	useEffect(() => {
        console.log(id);
        let tryout_soal = [];
        let question_soal = [];
		UseAPI('tryout_questions/'+id,{},'GET').then((tryout_resp)=>{
            tryout_resp.map((item)=> {
                question_soal.push(item.question_id);
            })
		})

		UseAPI('questions',{},'GET').then((question)=>{
            console.log("question_soal",question_soal);
            question_soal.map((item)=>{
                console.log(item);
                question.data.map((subitem,subkey)=>{
                    if (subitem.id == item) {
                        console.log(subkey);
                        question.data.splice(subkey,1)
                    }
                })
            })
            setData(question.data); 
		})
        console.log("data",data);
        // console.log("tryout_soal",tryoutSoal);
	}, []);

	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	}, useFilters, useGlobalFilter, usePagination)

	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance


	const {globalFilter, pageIndex} = state  
    const klaim = (id_soal,id_tryout,soal) => {
        console.log("soal",id_soal);
        console.log("tryout",id_tryout);

        swal({
            title: "Anda yakin ?",
            html:"true",
            text:'',
            icon: "warning",
            buttons: true,
          }).then((saved) => {
            if (saved) {
                UseAPI('tryout_questions',{question_id:id_soal,tryout_id:id_tryout},'POST').then((tryout_resp)=>{

                    let tryout_soal = [];
                    let question_soal = [];
                    UseAPI('tryout_questions/'+id,{},'GET').then((tryout_resp)=>{
                        tryout_resp.map((item)=> {
                            question_soal.push(item.question_id);
                        })
                    })
            
                    UseAPI('questions',{},'GET').then((question)=>{
                        console.log("question_soal",question_soal);
                        question_soal.map((item)=>{
                            console.log(item);
                            question.data.map((subitem,subkey)=>{
                                if (subitem.id == item) {
                                    console.log(subkey);
                                    question.data.splice(subkey,1)
                                }
                            })
                        })
                        setData(question.data); 
                    })
                               
                    
                })
                  
              swal("Soal berhasil di klaim", {
                icon: "success",
              });
            }
        })        
    }

	return (
		<>
		<PageTitle activeMenu="Klaim" motherMenu="Soal" />
		<div className="card">
			<div className="card-header">
				<h4 className="card-title">Klaim Soal</h4>
			</div>
			<div className="card-body">
				<div className="table-responsive">
					{/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
					<table {...getTableProps()} className="table display">
						<thead>
						{headerGroups.map(headerGroup => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									<th style={{width:'15%'}}>										
										Aksi
									</th>

									{headerGroup.headers.map(column => (
										<th {...column.getHeaderProps()} style={{width:column.width}}>
											{column.render('Header')}
											{console.log(column)}
											{column.canFilter ? column.render('Filter') : null}
										</th>
									))}
								</tr>
						))}
						</thead> 
						<tbody {...getTableBodyProps()} className="" >
						
							{page.map((row) => {
								prepareRow(row)
								return(
									<tr {...row.getRowProps()}>
										<td>
                                            <Button className="me-2" variant="primary btn-rounded" onClick={()=>klaim(row.original.id,id,row.original.question_text)}>
                                                <span className="btn-icon-start text-primary">
                                                    <i className="fa fa-download color-primary" />
                                                </span>
                                                Klaim
                                            </Button>                                            
										</td>
										{row.cells.map((cell) => {
											if (cell.column.id !== 'question_text') {
												return <td key={uuid()} {...cell.getCellProps()}> {cell.render('Cell')} </td>
											} else {													
												return (
												<td key={uuid()}>

													<Editor 
													disabled={true}
													initialValue={cell.row.values.question_text.length >= 5000 ? cell.row.values.question_text : cell.row.values.question_text.length >= 70 ? cell.row.values.question_text.substr(0,70) + '...' : cell.row.values.question_text}
													inline={true}
													init={{
														menubar: false,
														statusbar: false,
														toolbar: false                        
													}}
													/>


												</td>											
												)

											}
										})}
									</tr>
								)
							})}
						</tbody>
					</table>
					<div className="d-flex justify-content-between">
						<span>
							Page{' '}
							<strong>
								{pageIndex + 1} of {pageOptions.length}
							</strong>{''}
						</span>
						<span className="table-index">
							Go to page : {' '}
							<input type="number" 
								className="ml-2"
								defaultValue={pageIndex + 1} 
								onChange = {e => { 
									const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
									gotoPage(pageNumber)
								} } 
							/>
						</span>
					</div>
					<div className="text-center mb-3">	
						<div className="filter-pagination  mt-3">
							<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
							
							<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
								Previous
							</button>
							<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
								Next
							</button>
							<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>

	);
}

export default TryoutSoalTambah;
