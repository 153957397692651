import React, { Fragment, useCallback, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { UseAPI } from "../../../services/UseAPI";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Row, Col, Card,  Tab, Nav } from "react-bootstrap";
import FormElementsVertical from "./FormElementsVerical";

const QuestionTambah = () => {
  const [data, setData] = useState([])
  const [field, setField] = useState([])
  const [inputs, setInputs] = useState({})

  
  const handleChange = (event) => {
    if (event) {
        const name = event.name;
        const value = event.value;    
        setInputs(values => ({...values, [name]: value}))
    } 
  }  

  const handleSave = (e) => {
    e.preventDefault();
    console.log(e.target);
    const allInputs = document.querySelectorAll('input[name], select[name], textarea[name]');
    const inputsData = {};
    allInputs.forEach(input => {
        inputsData[input.name] = input.value;
    });

    console.log(allInputs);
    swal({
      title: "Anda yakin ?",
      icon: "warning",
      buttons: true,
    }).then((willSave) => {
      if (willSave) {        
        UseAPI('questions',inputsData,'POST').then((resp)=>{
          console.log(resp);
          if (resp.status === 'ok') {
            setData(resp.data);
            swal(resp.pesan, {
              icon: "success",
            });    
          } else {
            swal(resp.pesan, {
              icon: "danger",
            });    
          }
        });
      }
    })
  }

  useEffect(()=>{
      UseAPI('questions',{},'GET').then((resp)=>{
          console.log("questions",resp);
          setField(resp.field);
      });    
  },[])
  

  const tabData = [
    {
      label:"Soal",
      name: "question_text",
      icon: "newspaper",
      content: ""
    },
    {
      label:"Pil A",
      name: "option_a",
      icon: "list-ol",
      content: ""
    },
    {
      label:"Pil B",
      name: "option_b",
      icon: "list-ol",
      content: ""
    },

    {
      label:"Pil C",
      name: "option_c",
      icon: "list-ol",
      content: ""
    },
    {
      label:"Pil D",
      name: "option_d",
      icon: "list-ol",
      content: ""
    },
    {
      label:"Pil E",
      name: "option_e",
      icon: "list-ol",
      content: ""
    },
    {
      label:"Pembahasan",
      name: "explain",
      icon: "info",
      content: ""
    },
  ];
  
  const pilihan = 
    {
      label:'Pilihan Jawaban',
      tag:'select',
      type:'number',
      masterdata: [
        {
          id:'option_a',
          value:'A'
        },
        {
          id:'option_b',
          value:'B'
        },      
        {
          id:'option_c',
          value:'C'
        },      
        {
          id:'option_d',
          value:'D'
        },      
        {
          id:'option_e',
          value:'E'
        },      
      ]
    }
  

  return (
    <Fragment>
      <PageTitle
        motherMenu="Question"
        activeMenu="Tambah"
        pageContent="Question"
      />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Tambah</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSave}>

                  <div className="custom-tab-1">
                    <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                      <Nav as="ul" className="nav-tabs">
                        {tabData.map((data, i) => (
                          <Nav.Item as="li" key={i}>
                            <Nav.Link eventKey={data.name.toLowerCase()}>
                              <i className={`fas fa-${data.icon} me-3`} />
                              {data.label}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                      <Tab.Content className="pt-4">
                        {tabData.map((data, i) => (
                          <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                            <FormElementsVertical                              
                                name={data.name}
                                type={'textarea'}
                            />

                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                  <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">Mata Kuliah</label>
                      <div className="col-sm-9">
                      <select
                          name={'categories_of_question_id'}
                          label={'Kategori'}
                          onChange={(e)=>handleChange(e.target)}
                          className="form-control"
                      >
                          <option key={'0'} value={'0'}>Pilih Kategori</option>
                          {
                            console.log(
                              console.log("field.categories_of_question_id",field.categories_of_question_id)

                            )
                          }
                          {
                              field.categories_of_question_id && field.categories_of_question_id.masterdata.map((item,key)=> {
                                  // if (parseInt(item.id) === parseInt(props.value)) {
                                      return <option key={key} value={item.id}>{item.category_name}</option>
                                  // } else {
                                      // return <option key={key} value={item.value} >{item.label}</option>
                                  // }
                              })
                          }
                      </select>
                      </div>
                  </div>              
                  <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">Pilihan Jawaban</label>
                      <div className="col-sm-9">
                      <select
                          name={'correct_answer'}
                          label={'Jawaban'}
                          onChange={(e)=>handleChange(e.target)}
                          className="form-control"
                      >
                          <option key={'0'} value={'0'}>Pilih Jawaban</option>
                          {
                              pilihan.masterdata.map((item,key)=> {
                                console.log(item);
                                  // if (parseInt(item.id) === parseInt(props.value)) {
                                      return <option key={key} value={item.id}>{item.value}</option>
                                  // } else {
                                      // return <option key={key} value={item.value} >{item.label}</option>
                                  // }
                              })
                          }
                      </select>
                      </div>
                  </div>              
                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-sm btn-primary">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />Simpan
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        
      </div>      

    </Fragment>
  );
};

export default QuestionTambah;
