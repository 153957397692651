import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { UseAPI } from '../../../services/UseAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpenReader, faBug, faBugs, faClock, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import TryoutHasil from './TryoutHasil';
import TryoutHasilPeserta from './TryoutHasilPeserta';

const TryoutDetail = () => {
	const history = useHistory();
    const { id } = useParams();
	const [userRole, setUserRole] = useState('');
	const [data, setData] = useState([]);
	const [countDown, setCountDown] = useState(0); // Added state for countdown

	useEffect(() => {
    let endTime;
		const fetchData = async () => {
			const response = await UseAPI(`beranda/${id}`, {}, 'GET');
      console.log(response);
			setData(response);
			// Assuming response contains the start and end time of the tryout
			const startTime = new Date(response[0].start_datetime).getTime();
			endTime = new Date(response[0].exam_time_finish).getTime();
			const currentTime = Date.now();
			const distance = endTime - currentTime;
			setCountDown(distance);
		};

    fetchData(); // Fetch data on first mount
		const interval = setInterval(() => {
			const currentTime = Date.now();
			const distance = endTime - currentTime;
			setCountDown(distance);
		}, 1000); // Update countdown every second
		return () => clearInterval(interval); // Cleanup function to stop the interval on component unmount
	}, []);

	return (
    <>
      <div className="row">
        {data &&
          data.map((item, key) => (
            <div className="col-12 col-md-8" key={key}>
              <div className="card">
                <div className="card-header py-3">
                  <h4 className="mb-0">{item.tryout_name}</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-6 col-sm-6 col-6">
                      <div className="card overflow-hidden shadow">
                        <div className="card-header border-0">
                          <div className="d-flex">
                            <span className="justify-content-center align-self-center mt-2">
                              <i className="fas fa-3x fa-users"></i>
                            </span>
                            <div className="invoices">
                              <h4>{item.total_peserta}</h4>
                              <span>Peserta</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 col-6">
                      <div className="card overflow-hidden shadow">
                        <div className="card-header border-0">
                          <div className="d-flex">
                            <span className="justify-content-center align-self-center mt-2">
                              <i className="fas fa-3x fa-layer-group"></i>
                            </span>
                            <div className="invoices">
                              <h4>{item.total_soal}</h4>
                              <span>Jumlah Soal</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col-12 col-md-3">
                      <Button className="btn btn-primary btn-sm">
                        Mulai : {item.start_datetime}
                      </Button>
                    </div>
                    <div className="col-12 col-md-3">
                      <Button className="btn btn-primary btn-sm">
                        Akhir : {item.exam_time_finish}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        <div className="col-12 col-md-4">
          <div className="card">
            <div className="card-header py-3">
              <h4 className="mb-0">Waktu</h4>
            </div>
            <div className="card-body">
              <div className="w-100">
                <div className="text-center" style={{ fontSize: "5rem" }}>
                  {new Date(countDown).toISOString().substr(11, 8)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TryoutHasilPeserta title="false" />
    </>
  );
}
export default TryoutDetail;

