import React, { Fragment, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { UseAPI } from "../../../services/UseAPI";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import FormElements from "./FormElements";
import { Editor } from "@tinymce/tinymce-react";


const TryoutTambah = () => {
  const [data, setData] = useState([])
  const [field, setField] = useState([])
  const [inputs, setInputs] = useState({});
  const handleChange = (event) => {
    if (event) {
        console.log("event",event);
        const name = event.name;
        const value = event.value;    
        setInputs(values => ({...values, [name]: value}))
    } else {
        console.log(event)
    }
  }  

    const handleEditorChange = (event) => {
        if (event) {
            const name = event.target.id;
            const value = event.target.getContent();    
            setInputs(values => ({...values, [name]: value}))
        } else {
            console.log(event)
        }

    };
        

  const handleSave = (e) => {

    console.log(inputs);
    e.preventDefault();

    e.preventDefault();

    const allInputs = document.querySelectorAll('input, select, textarea');
    const inputsData = {};
    allInputs.forEach(input => {
        inputsData[input.name] = input.value;
    });

    console.log(inputsData);
    swal({
      title: "Anda yakin ?",
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willSave) => {
      if (willSave) {
        UseAPI('tryouts',inputsData,'POST').then((resp)=>{
          console.log(resp);
          if (resp.status === 'ok') {
            setData(resp.data);
            swal(resp.pesan, {
              icon: "success",
            });    
          } else {
            swal(resp.pesan, {
              icon: "danger",
            });    
          }
        });
      }
    })
  }

  useEffect(()=>{
      UseAPI('tryouts',{},'GET').then((resp)=>{
          console.log(resp);
          setField(resp.field);
      });    
  },[])
  

  
  

  return (
    <Fragment>
      <PageTitle
        motherMenu="Ujian"
        activeMenu="Tambah"
        pageContent="Ujian"
      />

      <div className="row">
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Tambah</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSave}>
                    <FormElements
                        onChange={(e)=>handleChange(e.target)}
                        name={'tryout_name'}
                        label={'Nama Ujian'}
                        type={'text'}
                    />
                    <FormElements
                        onChange={(e) => handleChange(e.target)}
                        name={'start_date'}
                        label={'Mulai'}
                        type={'date'}
                    />
                    <FormElements
                        onChange={(e) => handleChange(e.target)}
                        name={'end_date'}
                        label={'Selesai'}
                        type={'date'}
                    />
                    <FormElements
                        onChange={(e) => handleChange(e.target)}
                        name={'exam_time'}
                        label={'Pukul'}
                        type={'time'}
                    />
                    <FormElements
                        onChange={(e) => handleChange(e.target)}
                        name={'exam_duration_minutes'}
                        label={'Durasi (menit)'}
                        type={'number'}
                    />

                    <FormElements
                        onChange={(e)=>handleChange(e.target)}
                        name={'types_of_tryout_id'}
                        label={'Kategori Ujian'}
                        type={'select'}
                        datasource={field.types_of_tryout_id}
                    />

                    <FormElements
                        onChange={(e)=>handleEditorChange(e)}
                        name={'description'}
                        label={'Keterangan'}
                        type={'textarea'}
                        datasource={field.types_of_tryout_id}
                    />

                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-sm btn-primary">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />Simpan
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        
      </div>      

    </Fragment>
  );
};

export default TryoutTambah;
